import * as React from 'react';

// @mui
import { Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';

import AppWelcome from '../components/AppWelcome';

import {AboutDataset} from "../sections";
import {CompanyData} from "../data";

// ----------------------------------------------------------------------

export default function HomePage() {
    const { themeStretch } = useSettings();
    const theme = useTheme();

    return (
        <DashboardLayout>
            <Page title="Polkawatch">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <AppWelcome
                              title={'Welcome to Polkawatch'}
                              subtitle={'Polkadot Decentralization Analytics'}
                              description={'Learn about Polkadot Public Validation Network. Lets help decentralize Polkadot with a smart Nomination !'}
                              action={'Review my Nomination'}
                              url={'/nomination'}
                              action2={'Learn more'}
                              url2={CompanyData.learn_more_url}
                            />
                        </Grid>
                        <AboutDataset/>
                    </Grid>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
