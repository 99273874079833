import * as React from 'react';

// @mui
import { Container, Typography } from "@mui/material";
// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';
import { NominatorDetail } from "../sections";

import {usePolkadotExtensionContext} from "../contexts/PolkadotExtensionContext";

// ----------------------------------------------------------------------

export default function NominationPage() {
    const { themeStretch } = useSettings();

  const {selectedAccount} = usePolkadotExtensionContext();

  return (
      <DashboardLayout>
          <Page title="Nomination">
              <Container maxWidth={themeStretch ? false : 'xl'}>
                {
                  selectedAccount ?
                    (
                      <NominatorDetail
                        nominatorLabel={selectedAccount.meta.name}
                        nominatorId={selectedAccount.address}/>
                    ) :
                    (
                      <Typography>Connect Your wallet</Typography>
                    )
                }
              </Container>
          </Page>
      </DashboardLayout>
    );
}
