import * as React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';
import { ValidationOperatorOverview } from "../sections";

// ----------------------------------------------------------------------

export default function ValidationPage() {
    const { themeStretch } = useSettings();

    return (
        <DashboardLayout>
            <Page title="Validation">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <ValidationOperatorOverview/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
