import * as React from 'react';

// @mui
import { Container, Grid, useTheme, Typography } from "@mui/material";

// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';


import {RegionOverview} from '../sections';


// ----------------------------------------------------------------------

export default function GeographyPage() {
    const { themeStretch } = useSettings();

    return (
        <DashboardLayout>
            <Page title="Rewards by Geography">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <RegionOverview/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
