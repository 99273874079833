import * as React from 'react';

// @mui
import { Container, Grid, Typography, useTheme } from "@mui/material";
// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';

import { NetworkOverview } from "../sections";


// ----------------------------------------------------------------------

export default function NetworkPage() {
    const { themeStretch } = useSettings();
    const theme = useTheme();

    return (
        <DashboardLayout>
            <Page title="Rewards by Network">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <NetworkOverview/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
