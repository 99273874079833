import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';
import Page from '../../components/Page';

import { OperatorDetail } from "../../sections";

// ----------------------------------------------------------------------

export default function OperatorDetailPage({operatorId,operatorLabel}) {
    const { themeStretch } = useSettings();

    return (
        <DashboardLayout>
          <Page title={`Operator Detail: ${operatorLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <OperatorDetail operatorId={operatorId} operatorLabel={operatorLabel}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
