import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';

import Page from '../../components/Page';

import { CountryDetail } from "../../sections";

// ----------------------------------------------------------------------

export default function PageHome({countryId,countryLabel}) {
    const { themeStretch } = useSettings();

    return (
        <DashboardLayout>
          <Page title={`Country Detail: ${countryLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <CountryDetail countryId={countryId} countryLabel={countryLabel}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
