exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-detail-country-tsx": () => import("./../../../src/pages/detail/country.tsx" /* webpackChunkName: "component---src-pages-detail-country-tsx" */),
  "component---src-pages-detail-network-tsx": () => import("./../../../src/pages/detail/network.tsx" /* webpackChunkName: "component---src-pages-detail-network-tsx" */),
  "component---src-pages-detail-operator-tsx": () => import("./../../../src/pages/detail/operator.tsx" /* webpackChunkName: "component---src-pages-detail-operator-tsx" */),
  "component---src-pages-detail-region-tsx": () => import("./../../../src/pages/detail/region.tsx" /* webpackChunkName: "component---src-pages-detail-region-tsx" */),
  "component---src-pages-geography-tsx": () => import("./../../../src/pages/geography.tsx" /* webpackChunkName: "component---src-pages-geography-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-network-tsx": () => import("./../../../src/pages/network.tsx" /* webpackChunkName: "component---src-pages-network-tsx" */),
  "component---src-pages-nomination-tsx": () => import("./../../../src/pages/nomination.tsx" /* webpackChunkName: "component---src-pages-nomination-tsx" */),
  "component---src-pages-validation-tsx": () => import("./../../../src/pages/validation.tsx" /* webpackChunkName: "component---src-pages-validation-tsx" */)
}

